<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col">
          <h4 class="title is-2">{{ $t('imports.importClaims') }}</h4>
          <v-file-input
            accept=".csv"
            v-model="importFile"
            counter
            show-size
            truncate-length="15"
            @change="parseImportFile"
          ></v-file-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h4 class="title is-2">{{ $t('imports.importPreview') }}</h4>
          <v-data-table
            :headers="headersForImport"
            :items="dataForImport"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.outcome="{ item }">
              <span>
                {{ truncate(item.outcome, 8) }}
              </span>
            </template>
            <template v-slot:item.contacts="{ item }">
              <span>
                {{ truncate(item.contacts, 8) }}
              </span>
            </template>
            <template v-slot:item.description="{ item }">
              <span>
                {{ truncate(item.description, 18) }}
              </span>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <v-btn
            color="success"
            class="ma-4"
            :disabled="dataForImport.length < 1"
            @click="importMTPLs"
            >{{ $t('imports.import') }}</v-btn
          >
          <v-btn color="warning" class="ma-4" @click="clearImportData">{{
            $t('imports.clear')
          }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dayjs from 'dayjs'

const headerMap = {
  'Žalos numeris': ['Claim', ''],
  Klientas: ['Client', 'clientName'],
  'Valstybinis nr.': ['Vehicle Number', 'vehicleNumber'],
  'Įvykio data': ['Incident Date', 'incidentDate'],
  'Gautas pranešimas': ['Reported Date', 'reportedDate'],
  Vairuotojas: ['Driver', 'driverName'],
  Aplinkybės: ['Description', 'description'],
  'Įvykio vieta': ['Location', 'location'],
  'Uždarymas/rezultatas': ['Result', 'outcome'],
  Nuotraukos: ['Photo', 'photo'],
  'Policijos pažyma/deklaracija': ['Declaration', 'declaration'],
  Sumokėta: ['Paid', 'amountPaid'],
  Kontaktai: ['Contacts', 'contacts'],
  'Žalos nr.,,,,,,,,,,,,,,': ['Claim Number', 'insuranceNumber'],
}

export default {
  data() {
    return {
      dataForImport: [],
      headersForImport: [],
      importFile: null,
    }
  },
  methods: {
    parseImportFile(file) {
      if (file) {
        let papaConfig = {
          transformHeader: this.transformHeaders,
          complete: this.prepareData,
          delimiter: ';',
          header: true,
          delimitersToGuess: [';', '\t', '|', ','],
          encoding: 'utf8',
        }
        this.$papa.parse(file, papaConfig)
      } else {
        this.dataForImport = []
        this.headersForImport = []
      }
    },
    prepareData(results) {
      this.dataForImport = results.data
      const pairingArray = {}
      for (const property in headerMap) {
        pairingArray[headerMap[property][1]] = headerMap[property][0]
      }
      const adjustedHeaders = results.meta.fields.map((field) => {
        if (Object.hasOwn(pairingArray, field)) {
          return {
            text: pairingArray[field],
            value: field,
          }
        }
      })
      this.headersForImport = adjustedHeaders
    },
    transformHeaders(h) {
      if (Object.hasOwn(headerMap, h)) {
        return headerMap[h][1]
      } else {
        return h
      }
    },
    clearImportData() {
      // TODO: change to input ref instead of v-model null'ing
      this.importFile = null
      this.dataForImport = []
      this.headersForImport = []
    },
    truncate(input, length = 5) {
      return input.length > length ? `${input.substring(0, length)}...` : input
    },
    importMTPLs() {
      //TODO: Maybe we should ignore clientname and just send with pre-set clientID?
      const dataToImport = this.dataForImport.map((data) => {
        let {
          clientName,
          vehicleNumber,
          incidentDate,
          reportedDate,
          driverName,
          description,
          amountPaid,
          location,
          outcome,
        } = data
        amountPaid = amountPaid * 100
        incidentDate = new Date(data.incidentDate)
        reportedDate = new Date(data.reportedDate)
        return {
          clientName,
          vehicleNumber,
          incidentDate,
          reportedDate,
          driverName,
          description,
          amountPaid,
          location,
          outcome,
        }
      })
      // console.log(dataToImport)
      this.$store.dispatch('mtpl/importClaims', dataToImport)
    },
  },
}
</script>

<style lang="scss" scoped></style>
